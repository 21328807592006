// TEMA NORMAL
.dropdown{
    .dropdown-menu {
        background-color: rgb(255,255,255);
        border: 1px solid rgb(212, 212, 212);

        a.dropdown-item,
        a.dropdown-item i{
            color: black !important;
        }
    }
}


//TEMA DARK
.dark,
.dark_degrade{
    .dropdown{
        .btn {
            color: $gray-400 !important;  
        }
        .btn.show,
        .btn:hover {
            color: $yellow-500 !important;  
        }

        .dropdown-menu {
            border: 1px solid rgb(66, 66, 66) !important;
            background-color: $bg_subheader_dark !important;
            
            .dropdown-item{
                color: $gray-400 !important;
            }
            .dropdown-item i{
                color: $yellow-500 !important;
            }

            .dropdown-item:hover{
                color: $yellow-500 !important;
                background-color: transparent;
            }
        }
    }
}