.dark,
.dark_degrade{
    
    .NgxEditor__MenuBar,
    .NgxEditor{
        background-color: #1c1c1c;
    }

    .NgxEditor__Popup{
        background-color: #252525;
    }

    .NgxEditor__Popup .NgxEditor__Popup--Col {
        input{
            background-color: #252525;
        }
    }

    .NgxEditor__Seperator {
        border-color: #383613;
    }

    .NgxEditor__Dropdown .NgxEditor__Dropdown--Text,
    .NgxEditor__MenuItem .NgxEditor__MenuItem--Icon { 
        color: #abada3;
    }
    
}