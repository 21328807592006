.dark,
.dark_degrade{
    .ng-select-container{
        background-color: $gray-800;
        
        .ng-value-container,
        .ng-arrow-wrapper,
        .ng-clear-wrapper{
            background-color: $gray-800;
        }
    }
    
    .ng-select .ng-select-container{
        color: $gray-400;
        border-color: $gray-800;   
    }
    
    .ng-dropdown-panel 
    {
        .ng-dropdown-panel-items .ng-option,
        .ng-dropdown-panel-items .ng-option.ng-option-marked {
            background-color: $gray-900;
            color: $gray-400;
        }
    
        .ng-option-selected, 
        .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{
            color: $gray-400;
            background-color: $gray-800;
        }

        border-color: $gray-800;
    }
    
    .ng-placeholder{
        color: $gray-600;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
        background-color: $gray-900;
    }
}