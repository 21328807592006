/*-------LAYOUT---------*/
._layout_horizontal{
    .topnav{
        width: 100%;
    }
    .submenu-header_horizontal{
        position: fixed;
        display: flex;
        align-items: center;
        top: 0;
        height: $topnav_height;
        background-color: $bg_sidenav;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 12px;
        padding: 0 1rem;
        width: 100%;
        z-index: 9;
        transition: top 0.25s;
    }

    .submenu-header_horizontal_show{
        top: $topnav_height !important;
        transition: top 0.25s;
    }
}

._layout_horizontal.sub-header-fixed{
    .submenu-header_horizontal{
        height: calc($topnav_height - 0.7rem);
        top: $topnav_height !important;
        transition: top 0.25s;
    }
}

._layout_vertical{
    .topnav{
        margin-left: $sidenav_width;
        width: $topnav_width;
    }
}
._layout_vertical.mini-sidenav{
    .topnav{
        width: calc(100% - 80px);
        margin-left: 80px;
    }
}

.topnav{
    position: fixed;
    display: flex;
    align-items: center;
    height: $topnav_height;
    background-color: $bg_topnav;
    padding: 0 1rem;

    z-index: 11;

    .topnav-header > #logo-sidenav{
        height: calc($topnav_height - 10px);
    }

    a,
    button{
        color: $icon_topnav_color !important;
        i{
            color: $icon_topnav_color;
        }
    }
}

.topnav-itens{
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    width: 100%;
}

.topnav-itens .topnav_username{
    h5{
        font-size: 1rem;
        color: white;
        margin: 0 auto;
    }
    h6{
        font-size: 0.9rem;
        color: $icon_topnav_color_dark;
        margin: 0 auto;
    }
}




/*-------VERSÃO MOBILE-------*/
.mobile-sidenav{
    .topnav{
        width: 100%;
        margin-left: 0;

        .topnav-itens{
            .topnav_username{
                display: none;
            }
        }
    }  
}


//////////////////////////////////////////////
//////////////////DARK MODE*//////////////////
//////////////////////////////////////////////
.dark{
    .topnav{
        background-color: $bg_topnav_dark;
    }

    .submenu-header_horizontal{
        background-color: $bg_sidenav_dark;
        border-bottom: 1px solid $gray-700;
        border-top: 1px solid $gray-700;
    }
}

.dark_degrade{
    .topnav{
        background: rgb(32,32,32);
        background: linear-gradient(90deg, rgba(35,35,35,1) 0%, rgba(25,40,50,1) 50%, rgba(5,35,15,1) 100%);
    }

    .submenu-header_horizontal{
        background: rgb(32,32,32);
        background: linear-gradient(90deg, rgba(40,40,40,1) 0%, rgba(30,45,55,1) 50%, rgba(10,40,20,1) 100%);
        border-bottom: 1px solid $gray-700;
        border-top: 1px solid $gray-700;
    }
}

._layout_horizontal.dark,
._layout_vertical.dark,
.dark,
.dark_degrade{
    .topnav{
        a,
        button{
            color: $icon_topnav_color_dark !important;
            i{
                color: $icon_topnav_color_dark !important;
            }
        }
    }

    .topnav-itens .topnav_username{
        h5{
            color: white;
        }
        h6{
            color: $icon_topnav_color_dark;
        }
    }

    .header-item{
        i{
            color: $primary !important;
        }
        &:hover{
            background: transparent;
            background: radial-gradient(circle, rgba(0,255,30,0.1)0%, rgba(0,255,30,0)100%);
            span, i, a{
                color: $primary !important;
            }
        }
    }
    
}