label{
    font-weight: 500;
    margin-bottom: 0.5rem;

    .active{
        font-weight: 800;
        font-style: normal;
    }
}

.card-helper{
    padding: 1rem;
    border-radius: 1rem;
    border-left: .3rem solid rgb(50, 150, 50);
    background-color: rgba(120, 255, 150, 0.5);
    color: rgba(0,0,0,.8);
    
    .btn{
        border-radius: 1rem;
    }
}

.checkbox_item .checkbox_wrap{
	position: relative;
	display: block;
	cursor: pointer;
	width: 2rem;
	margin: 0 auto;
}

.checkbox_item:last-child .checkbox_wrap{
	margin-bottom: 0;
}

.checkbox_item .checkbox_wrap .checkbox_inp{
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: 1;
}

.checkbox_item .checkbox_wrap .checkbox_mark{
  box-sizing: border-box;
	display: inline-block;
	position: relative;
	border-radius: 25px;
}

.checkbox_item .checkbox_wrap .checkbox_mark:before,
.checkbox_item .checkbox_wrap .checkbox_mark:after{
	content: "";
	position: absolute;
	transition: all 0.5s ease;
}

/* flat styles */
.checkbox_item.citem_2 .checkbox_wrap .checkbox_mark{
	border: 2px solid rgba(0,0,0,.15);
	width: 2rem;
	height: 1.2rem;
	padding: 0;
}

.checkbox_item.citem_2 .checkbox_wrap .checkbox_mark:before{
	top: 0px;
    left: 0;
    width: calc(1rem - 1px);
    height: calc(1rem - 1px);
    background: rgba(0,0,0,.15);
    border-radius: 25px;
}

.checkbox_item.citem_2 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark{
	border-color: $primary;
}

.checkbox_item.citem_2 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark:before{
	left: 0.85rem;
	background: $primary;
}

.input-color{
    border: 1px solid gray;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    cursor: pointer;
}

.dark,
.dark_degrade{

    .checkbox_item.citem_2 .checkbox_wrap .checkbox_mark{
        border: 2px solid rgba(255,255,255,.15);
    }
    
    .checkbox_item.citem_2 .checkbox_wrap .checkbox_mark:before{
        background: rgba(255,255,255,.15);
    }

    .card-helper{
        background-color: rgba(5, 5, 10, 0.5);
    }

    .hover:hover{
        background-color: rgba(0, 0, 0, 0.2);
    }

    .form-label > span.active{
        color: $primary;
    }
    
    .form-label > span.no-active{
        color: $gray-500;
    }

    .text-success {
        color: $primary;
    }
}

.hover:hover{
    background-color: rgba(0, 0, 0, 0.05);
}

.text-left{
    text-align: left;
}

.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.flex-column-reverse {
    flex-direction: column-reverse;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.flex-fill {
    flex: 1 1 auto;
}

.flex-grow-0 {
    flex-grow: 0;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-shrink-1 {
    flex-shrink: 1;
}

.justify-content-start {
    justify-content: flex-start;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-around {
    justify-content: space-around;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-end {
    align-items: flex-end;
}

.align-items-center {
    align-items: center;
}

.align-items-baseline {
    align-items: baseline;
}

.align-items-stretch {
    align-items: stretch;
}

.align-content-start {
    align-content: flex-start;
}

.align-content-end {
    align-content: flex-end;
}

.align-content-center {
    align-content: center;
}

.align-content-between {
    align-content: space-between;
}

.align-content-around {
    align-content: space-around;
}

.align-content-stretch {
    align-content: stretch;
}

.align-self-auto {
    align-self: auto;
}

.align-self-start {
    align-self: flex-start;
}

.align-self-end {
    align-self: flex-end;
}

.align-self-center {
    align-self: center;
}

.align-self-baseline {
    align-self: baseline;
}

.align-self-stretch {
    align-self: stretch;
}

@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row;
    }
    .flex-sm-column {
        flex-direction: column;
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse;
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse;
    }
    .flex-sm-wrap {
        flex-wrap: wrap;
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap;
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse;
    }
    .flex-sm-fill {
        flex: 1 1 auto;
    }
    .flex-sm-grow-0 {
        flex-grow: 0;
    }
    .flex-sm-grow-1 {
        flex-grow: 1;
    }
    .flex-sm-shrink-0 {
        flex-shrink: 0;
    }
    .flex-sm-shrink-1 {
        flex-shrink: 1;
    }
    .justify-content-sm-start {
        justify-content: flex-start;
    }
    .justify-content-sm-end {
        justify-content: flex-end;
    }
    .justify-content-sm-center {
        justify-content: center;
    }
    .justify-content-sm-between {
        justify-content: space-between;
    }
    .justify-content-sm-around {
        justify-content: space-around;
    }
    .align-items-sm-start {
        align-items: flex-start;
    }
    .align-items-sm-end {
        align-items: flex-end;
    }
    .align-items-sm-center {
        align-items: center;
    }
    .align-items-sm-baseline {
        align-items: baseline;
    }
    .align-items-sm-stretch {
        align-items: stretch;
    }
    .align-content-sm-start {
        align-content: flex-start;
    }
    .align-content-sm-end {
        align-content: flex-end;
    }
    .align-content-sm-center {
        align-content: center;
    }
    .align-content-sm-between {
        align-content: space-between;
    }
    .align-content-sm-around {
        align-content: space-around;
    }
    .align-content-sm-stretch {
        align-content: stretch;
    }
    .align-self-sm-auto {
        align-self: auto;
    }
    .align-self-sm-start {
        align-self: flex-start;
    }
    .align-self-sm-end {
        align-self: flex-end;
    }
    .align-self-sm-center {
        align-self: center;
    }
    .align-self-sm-baseline {
        align-self: baseline;
    }
    .align-self-sm-stretch {
        align-self: stretch;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row;
    }
    .flex-md-column {
        flex-direction: column;
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse;
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse;
    }
    .flex-md-wrap {
        flex-wrap: wrap;
    }
    .flex-md-nowrap {
        flex-wrap: nowrap;
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse;
    }
    .flex-md-fill {
        flex: 1 1 auto;
    }
    .flex-md-grow-0 {
        flex-grow: 0;
    }
    .flex-md-grow-1 {
        flex-grow: 1;
    }
    .flex-md-shrink-0 {
        flex-shrink: 0;
    }
    .flex-md-shrink-1 {
        flex-shrink: 1;
    }
    .justify-content-md-start {
        justify-content: flex-start;
    }
    .justify-content-md-end {
        justify-content: flex-end;
    }
    .justify-content-md-center {
        justify-content: center;
    }
    .justify-content-md-between {
        justify-content: space-between;
    }
    .justify-content-md-around {
        justify-content: space-around;
    }
    .align-items-md-start {
        align-items: flex-start;
    }
    .align-items-md-end {
        align-items: flex-end;
    }
    .align-items-md-center {
        align-items: center;
    }
    .align-items-md-baseline {
        align-items: baseline;
    }
    .align-items-md-stretch {
        align-items: stretch;
    }
    .align-content-md-start {
        align-content: flex-start;
    }
    .align-content-md-end {
        align-content: flex-end;
    }
    .align-content-md-center {
        align-content: center;
    }
    .align-content-md-between {
        align-content: space-between;
    }
    .align-content-md-around {
        align-content: space-around;
    }
    .align-content-md-stretch {
        align-content: stretch;
    }
    .align-self-md-auto {
        align-self: auto;
    }
    .align-self-md-start {
        align-self: flex-start;
    }
    .align-self-md-end {
        align-self: flex-end;
    }
    .align-self-md-center {
        align-self: center;
    }
    .align-self-md-baseline {
        align-self: baseline;
    }
    .align-self-md-stretch {
        align-self: stretch;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row;
    }
    .flex-lg-column {
        flex-direction: column;
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse;
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse;
    }
    .flex-lg-wrap {
        flex-wrap: wrap;
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap;
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse;
    }
    .flex-lg-fill {
        flex: 1 1 auto;
    }
    .flex-lg-grow-0 {
        flex-grow: 0;
    }
    .flex-lg-grow-1 {
        flex-grow: 1;
    }
    .flex-lg-shrink-0 {
        flex-shrink: 0;
    }
    .flex-lg-shrink-1 {
        flex-shrink: 1;
    }
    .justify-content-lg-start {
        justify-content: flex-start;
    }
    .justify-content-lg-end {
        justify-content: flex-end;
    }
    .justify-content-lg-center {
        justify-content: center;
    }
    .justify-content-lg-between {
        justify-content: space-between;
    }
    .justify-content-lg-around {
        justify-content: space-around;
    }
    .align-items-lg-start {
        align-items: flex-start;
    }
    .align-items-lg-end {
        align-items: flex-end;
    }
    .align-items-lg-center {
        align-items: center;
    }
    .align-items-lg-baseline {
        align-items: baseline;
    }
    .align-items-lg-stretch {
        align-items: stretch;
    }
    .align-content-lg-start {
        align-content: flex-start;
    }
    .align-content-lg-end {
        align-content: flex-end;
    }
    .align-content-lg-center {
        align-content: center;
    }
    .align-content-lg-between {
        align-content: space-between;
    }
    .align-content-lg-around {
        align-content: space-around;
    }
    .align-content-lg-stretch {
        align-content: stretch;
    }
    .align-self-lg-auto {
        align-self: auto;
    }
    .align-self-lg-start {
        align-self: flex-start;
    }
    .align-self-lg-end {
        align-self: flex-end;
    }
    .align-self-lg-center {
        align-self: center;
    }
    .align-self-lg-baseline {
        align-self: baseline;
    }
    .align-self-lg-stretch {
        align-self: stretch;
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row;
    }
    .flex-xl-column {
        flex-direction: column;
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse;
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse;
    }
    .flex-xl-wrap {
        flex-wrap: wrap;
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap;
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse;
    }
    .flex-xl-fill {
        flex: 1 1 auto;
    }
    .flex-xl-grow-0 {
        flex-grow: 0;
    }
    .flex-xl-grow-1 {
        flex-grow: 1;
    }
    .flex-xl-shrink-0 {
        flex-shrink: 0;
    }
    .flex-xl-shrink-1 {
        flex-shrink: 1;
    }
    .justify-content-xl-start {
        justify-content: flex-start;
    }
    .justify-content-xl-end {
        justify-content: flex-end;
    }
    .justify-content-xl-center {
        justify-content: center;
    }
    .justify-content-xl-between {
        justify-content: space-between;
    }
    .justify-content-xl-around {
        justify-content: space-around;
    }
    .align-items-xl-start {
        align-items: flex-start;
    }
    .align-items-xl-end {
        align-items: flex-end;
    }
    .align-items-xl-center {
        align-items: center;
    }
    .align-items-xl-baseline {
        align-items: baseline;
    }
    .align-items-xl-stretch {
        align-items: stretch;
    }
    .align-content-xl-start {
        align-content: flex-start;
    }
    .align-content-xl-end {
        align-content: flex-end;
    }
    .align-content-xl-center {
        align-content: center;
    }
    .align-content-xl-between {
        align-content: space-between;
    }
    .align-content-xl-around {
        align-content: space-around;
    }
    .align-content-xl-stretch {
        align-content: stretch;
    }
    .align-self-xl-auto {
        align-self: auto;
    }
    .align-self-xl-start {
        align-self: flex-start;
    }
    .align-self-xl-end {
        align-self: flex-end;
    }
    .align-self-xl-center {
        align-self: center;
    }
    .align-self-xl-baseline {
        align-self: baseline;
    }
    .align-self-xl-stretch {
        align-self: stretch;
    }
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.float-none {
    float: none;
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left;
    }
    .float-sm-right {
        float: right;
    }
    .float-sm-none {
        float: none;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left;
    }
    .float-md-right {
        float: right;
    }
    .float-md-none {
        float: none;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left;
    }
    .float-lg-right {
        float: right;
    }
    .float-lg-none {
        float: none;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left;
    }
    .float-xl-right {
        float: right;
    }
    .float-xl-none {
        float: none;
    }
}

.user-select-all {
    user-select: all;
}

.user-select-auto {
    user-select: auto;
}

.user-select-none {
    user-select: none;
}

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.position-static {
    position: static;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.position-fixed {
    position: fixed;
}

.position-sticky {
    position: sticky;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports (position: sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.shadow-none {
    box-shadow: none;
}

.w-25 {
    width: 25%;
}

.w-50 {
    width: 50%;
}

.w-75 {
    width: 75%;
}

.w-100 {
    width: 100%;
}

.w-auto {
    width: auto;
}

.h-25 {
    height: 25%;
}

.h-50 {
    height: 50%;
}

.h-75 {
    height: 75%;
}

.h-100 {
    height: 100%;
}

.h-auto {
    height: auto;
}

.mw-100 {
    max-width: 100%;
}

.mh-100 {
    max-height: 100%;
}

.min-vw-100 {
    min-width: 100vw;
}

.min-vh-100 {
    min-height: 100vh;
}

.vw-100 {
    width: 100vw;
}

.vh-100 {
    height: 100vh;
}

.m-0 {
    margin: 0;
}

.mt-0,
.my-0 {
    margin-top: 0;
}

.mr-0,
.mx-0 {
    margin-right: 0;
}

.mb-0,
.my-0 {
    margin-bottom: 0;
}

.ml-0,
.mx-0 {
    margin-left: 0;
}

.m-1 {
    margin: 0.25rem;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem;
}

.m-2 {
    margin: 0.5rem;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem;
}

.m-3 {
    margin: 1rem;
}

.mt-3,
.my-3 {
    margin-top: 1rem;
}

.mr-3,
.mx-3 {
    margin-right: 1rem;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem;
}

.ml-3,
.mx-3 {
    margin-left: 1rem;
}

.m-4 {
    margin: 1.5rem;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem;
}

.m-5 {
    margin: 3rem;
}

.mt-5,
.my-5 {
    margin-top: 3rem;
}

.mr-5,
.mx-5 {
    margin-right: 3rem;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem;
}

.ml-5,
.mx-5 {
    margin-left: 3rem;
}

.p-0 {
    padding: 0;
}

.pt-0,
.py-0 {
    padding-top: 0;
}

.pr-0,
.px-0 {
    padding-right: 0;
}

.pb-0,
.py-0 {
    padding-bottom: 0;
}

.pl-0,
.px-0 {
    padding-left: 0;
}

.p-1 {
    padding: 0.25rem;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem;
}

.p-2 {
    padding: 0.5rem;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem;
}

.p-3 {
    padding: 1rem;
}

.pt-3,
.py-3 {
    padding-top: 1rem;
}

.pr-3,
.px-3 {
    padding-right: 1rem;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem;
}

.pl-3,
.px-3 {
    padding-left: 1rem;
}

.p-4 {
    padding: 1.5rem;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem;
}

.p-5 {
    padding: 3rem;
}

.pt-5,
.py-5 {
    padding-top: 3rem;
}

.pr-5,
.px-5 {
    padding-right: 3rem;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem;
}

.pl-5,
.px-5 {
    padding-left: 3rem;
}

.m-n1 {
    margin: -0.25rem;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem;
}

.m-n2 {
    margin: -0.5rem;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem;
}

.m-n3 {
    margin: -1rem;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem;
}

.m-n4 {
    margin: -1.5rem;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem;
}

.m-n5 {
    margin: -3rem;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem;
}

.m-auto {
    margin: auto;
}

.mt-auto,
.my-auto {
    margin-top: auto;
}

.mr-auto,
.mx-auto {
    margin-right: auto;
}

.mb-auto,
.my-auto {
    margin-bottom: auto;
}

.ml-auto,
.mx-auto {
    margin-left: auto;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0;
    }
    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0;
    }
    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0;
    }
    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0;
    }
    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0;
    }
    .m-sm-1 {
        margin: 0.25rem;
    }
    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem;
    }
    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem;
    }
    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem;
    }
    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem;
    }
    .m-sm-2 {
        margin: 0.5rem;
    }
    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem;
    }
    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem;
    }
    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem;
    }
    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem;
    }
    .m-sm-3 {
        margin: 1rem;
    }
    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem;
    }
    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem;
    }
    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem;
    }
    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem;
    }
    .m-sm-4 {
        margin: 1.5rem;
    }
    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem;
    }
    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem;
    }
    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem;
    }
    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem;
    }
    .m-sm-5 {
        margin: 3rem;
    }
    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem;
    }
    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem;
    }
    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem;
    }
    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem;
    }
    .p-sm-0 {
        padding: 0;
    }
    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0;
    }
    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0;
    }
    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0;
    }
    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0;
    }
    .p-sm-1 {
        padding: 0.25rem;
    }
    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem;
    }
    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem;
    }
    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem;
    }
    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem;
    }
    .p-sm-2 {
        padding: 0.5rem;
    }
    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem;
    }
    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem;
    }
    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem;
    }
    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem;
    }
    .p-sm-3 {
        padding: 1rem;
    }
    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem;
    }
    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem;
    }
    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem;
    }
    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem;
    }
    .p-sm-4 {
        padding: 1.5rem;
    }
    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem;
    }
    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem;
    }
    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem;
    }
    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem;
    }
    .p-sm-5 {
        padding: 3rem;
    }
    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem;
    }
    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem;
    }
    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem;
    }
    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem;
    }
    .m-sm-n1 {
        margin: -0.25rem;
    }
    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem;
    }
    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem;
    }
    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem;
    }
    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem;
    }
    .m-sm-n2 {
        margin: -0.5rem;
    }
    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem;
    }
    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem;
    }
    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem;
    }
    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem;
    }
    .m-sm-n3 {
        margin: -1rem;
    }
    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem;
    }
    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem;
    }
    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem;
    }
    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem;
    }
    .m-sm-n4 {
        margin: -1.5rem;
    }
    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem;
    }
    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem;
    }
    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem;
    }
    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem;
    }
    .m-sm-n5 {
        margin: -3rem;
    }
    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem;
    }
    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem;
    }
    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem;
    }
    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem;
    }
    .m-sm-auto {
        margin: auto;
    }
    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto;
    }
    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto;
    }
    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto;
    }
    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0;
    }
    .mt-md-0,
    .my-md-0 {
        margin-top: 0;
    }
    .mr-md-0,
    .mx-md-0 {
        margin-right: 0;
    }
    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0;
    }
    .ml-md-0,
    .mx-md-0 {
        margin-left: 0;
    }
    .m-md-1 {
        margin: 0.25rem;
    }
    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem;
    }
    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem;
    }
    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem;
    }
    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem;
    }
    .m-md-2 {
        margin: 0.5rem;
    }
    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem;
    }
    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem;
    }
    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem;
    }
    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem;
    }
    .m-md-3 {
        margin: 1rem;
    }
    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem;
    }
    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem;
    }
    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem;
    }
    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem;
    }
    .m-md-4 {
        margin: 1.5rem;
    }
    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem;
    }
    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem;
    }
    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem;
    }
    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem;
    }
    .m-md-5 {
        margin: 3rem;
    }
    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem;
    }
    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem;
    }
    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem;
    }
    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem;
    }
    .p-md-0 {
        padding: 0;
    }
    .pt-md-0,
    .py-md-0 {
        padding-top: 0;
    }
    .pr-md-0,
    .px-md-0 {
        padding-right: 0;
    }
    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0;
    }
    .pl-md-0,
    .px-md-0 {
        padding-left: 0;
    }
    .p-md-1 {
        padding: 0.25rem;
    }
    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem;
    }
    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem;
    }
    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem;
    }
    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem;
    }
    .p-md-2 {
        padding: 0.5rem;
    }
    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem;
    }
    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem;
    }
    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem;
    }
    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem;
    }
    .p-md-3 {
        padding: 1rem;
    }
    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem;
    }
    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem;
    }
    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem;
    }
    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem;
    }
    .p-md-4 {
        padding: 1.5rem;
    }
    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem;
    }
    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem;
    }
    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem;
    }
    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem;
    }
    .p-md-5 {
        padding: 3rem;
    }
    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem;
    }
    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem;
    }
    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem;
    }
    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem;
    }
    .m-md-n1 {
        margin: -0.25rem;
    }
    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem;
    }
    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem;
    }
    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem;
    }
    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem;
    }
    .m-md-n2 {
        margin: -0.5rem;
    }
    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem;
    }
    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem;
    }
    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem;
    }
    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem;
    }
    .m-md-n3 {
        margin: -1rem;
    }
    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem;
    }
    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem;
    }
    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem;
    }
    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem;
    }
    .m-md-n4 {
        margin: -1.5rem;
    }
    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem;
    }
    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem;
    }
    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem;
    }
    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem;
    }
    .m-md-n5 {
        margin: -3rem;
    }
    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem;
    }
    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem;
    }
    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem;
    }
    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem;
    }
    .m-md-auto {
        margin: auto;
    }
    .mt-md-auto,
    .my-md-auto {
        margin-top: auto;
    }
    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto;
    }
    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto;
    }
    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0;
    }
    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0;
    }
    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0;
    }
    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0;
    }
    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0;
    }
    .m-lg-1 {
        margin: 0.25rem;
    }
    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem;
    }
    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem;
    }
    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem;
    }
    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem;
    }
    .m-lg-2 {
        margin: 0.5rem;
    }
    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem;
    }
    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem;
    }
    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem;
    }
    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem;
    }
    .m-lg-3 {
        margin: 1rem;
    }
    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem;
    }
    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem;
    }
    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem;
    }
    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem;
    }
    .m-lg-4 {
        margin: 1.5rem;
    }
    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem;
    }
    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem;
    }
    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem;
    }
    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem;
    }
    .m-lg-5 {
        margin: 3rem;
    }
    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem;
    }
    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem;
    }
    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem;
    }
    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem;
    }
    .p-lg-0 {
        padding: 0;
    }
    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0;
    }
    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0;
    }
    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0;
    }
    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0;
    }
    .p-lg-1 {
        padding: 0.25rem;
    }
    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem;
    }
    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem;
    }
    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem;
    }
    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem;
    }
    .p-lg-2 {
        padding: 0.5rem;
    }
    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem;
    }
    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem;
    }
    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem;
    }
    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem;
    }
    .p-lg-3 {
        padding: 1rem;
    }
    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem;
    }
    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem;
    }
    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem;
    }
    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem;
    }
    .p-lg-4 {
        padding: 1.5rem;
    }
    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem;
    }
    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem;
    }
    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem;
    }
    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem;
    }
    .p-lg-5 {
        padding: 3rem;
    }
    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem;
    }
    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem;
    }
    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem;
    }
    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem;
    }
    .m-lg-n1 {
        margin: -0.25rem;
    }
    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem;
    }
    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem;
    }
    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem;
    }
    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem;
    }
    .m-lg-n2 {
        margin: -0.5rem;
    }
    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem;
    }
    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem;
    }
    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem;
    }
    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem;
    }
    .m-lg-n3 {
        margin: -1rem;
    }
    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem;
    }
    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem;
    }
    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem;
    }
    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem;
    }
    .m-lg-n4 {
        margin: -1.5rem;
    }
    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem;
    }
    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem;
    }
    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem;
    }
    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem;
    }
    .m-lg-n5 {
        margin: -3rem;
    }
    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem;
    }
    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem;
    }
    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem;
    }
    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem;
    }
    .m-lg-auto {
        margin: auto;
    }
    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto;
    }
    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto;
    }
    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto;
    }
    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0;
    }
    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0;
    }
    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0;
    }
    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0;
    }
    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0;
    }
    .m-xl-1 {
        margin: 0.25rem;
    }
    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem;
    }
    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem;
    }
    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem;
    }
    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem;
    }
    .m-xl-2 {
        margin: 0.5rem;
    }
    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem;
    }
    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem;
    }
    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem;
    }
    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem;
    }
    .m-xl-3 {
        margin: 1rem;
    }
    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem;
    }
    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem;
    }
    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem;
    }
    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem;
    }
    .m-xl-4 {
        margin: 1.5rem;
    }
    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem;
    }
    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem;
    }
    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem;
    }
    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem;
    }
    .m-xl-5 {
        margin: 3rem;
    }
    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem;
    }
    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem;
    }
    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem;
    }
    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem;
    }
    .p-xl-0 {
        padding: 0;
    }
    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0;
    }
    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0;
    }
    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0;
    }
    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0;
    }
    .p-xl-1 {
        padding: 0.25rem;
    }
    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem;
    }
    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem;
    }
    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem;
    }
    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem;
    }
    .p-xl-2 {
        padding: 0.5rem;
    }
    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem;
    }
    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem;
    }
    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem;
    }
    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem;
    }
    .p-xl-3 {
        padding: 1rem;
    }
    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem;
    }
    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem;
    }
    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem;
    }
    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem;
    }
    .p-xl-4 {
        padding: 1.5rem;
    }
    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem;
    }
    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem;
    }
    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem;
    }
    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem;
    }
    .p-xl-5 {
        padding: 3rem;
    }
    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem;
    }
    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem;
    }
    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem;
    }
    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem;
    }
    .m-xl-n1 {
        margin: -0.25rem;
    }
    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem;
    }
    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem;
    }
    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem;
    }
    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem;
    }
    .m-xl-n2 {
        margin: -0.5rem;
    }
    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem;
    }
    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem;
    }
    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem;
    }
    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem;
    }
    .m-xl-n3 {
        margin: -1rem;
    }
    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem;
    }
    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem;
    }
    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem;
    }
    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem;
    }
    .m-xl-n4 {
        margin: -1.5rem;
    }
    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem;
    }
    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem;
    }
    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem;
    }
    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem;
    }
    .m-xl-n5 {
        margin: -3rem;
    }
    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem;
    }
    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem;
    }
    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem;
    }
    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem;
    }
    .m-xl-auto {
        margin: auto;
    }
    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto;
    }
    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto;
    }
    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto;
    }
    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto;
    }
}
