.card-agenda{
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-agenda-header,
.card-agenda-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-agenda-body {
    padding: 0.25rem;
    border-radius: 0.2rem;
    background-color: rgba(0,0,0,.1);
}

.legend-2{
    text-align: right;
}

.block-with-text {
    white-space: nowrap;
    max-height: 1.4rem;
    width: 100%;
    font-weight: 500;
    overflow: hidden;
    font-family: 'Montserrat';
    text-overflow: ellipsis;
}

@media only screen and (max-width: 770px){
    .legend-1,
    .legend-2{
        text-align: center;
    }  
}