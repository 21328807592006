//PACOTES DE LAYOUT
@import 'bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr-bs5-alert';

//FONTS
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #202020;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}