//---------------------------------------
//---------------SIDENAV----------------- 
//---------------------------------------
.sidenav {
    position: fixed;
    top: 0;
    left: 0;
    width: $sidenav_width;
    height: $sidenav_height;
    background-color: $bg_sidenav;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    z-index: 10;

    .sidenav-header {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        height: $topnav_height;
        background-color: $bg_topnav;

        #logo-sidenav {
            height: 4rem;
        }
    }

    .sidenav-body {
        overflow: hidden;

        //MENU
        .sidenav-menu {
            position: relative;
            display: block;

            li {
                margin-bottom: 1.5rem;

                a {
                    position: relative;
                    display: table;
                    span {
                        position: relative;
                        display: table-cell;
                    }
                }

                a.sidenav-expand-submenu::after {
                    content: '\f053';
                    font-family: FontAwesome;
                    font-size: .6rem;
                    color: $success;
                    position: absolute;
                    width: 10px;
                    margin-top: .6rem;
                    right: -2.5rem;
                    transition: width .25s, left .25s, transform .25s, margin-top .25s;
                }
                a.sidenav-expand-submenu.has-expanded-menu::after {
                    transform: rotate(-90deg);
                    margin-top: .5rem;
                } 
                  

                //SUBMENU
                ul {
                    li:first-child {
                        margin-top: 0.5rem;
                    }

                    li {
                        margin-bottom: 0.5rem;
                    }

                    li:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &:first-child {
                margin-top: 1.5rem;
            }
        }

        .sidenav-submenu.show {
            display: block;
        }

        .sidenav-submenu.hide {
            display: none;
        }
    }
}


//---------------------------------------
//-------------MINI-SIDENAV-------------- 
//---------------------------------------
.mini-sidenav {
    .sidenav {
        width: 80px;
        transition: all linear 0.1s;

        .sidenav-header {
            width: 80px;

            #logo-sidenav {
                height: 2rem;
            }
        }

        .sidenav-body {
            .sidenav-menu a span {
                display: none;
            }

            .sidenav-menu ul li a {
                visibility: hidden;
            }
        }

        &:hover {
            width: $sidenav_width;

            .sidenav-body {
                overflow: visible;

                .sidenav-menu a span {
                    display: table-cell;
                }

                .sidenav-menu ul li a {
                    visibility: visible;
                }
            }
        }
    }
}


//---------------------------------------
//-------------MOBILE MODE--------------- 
//---------------------------------------
.mobile-sidenav {

    .sidenav {
        height: calc(100% - $topnav_height);
        top: $topnav_height;
        left: -$sidenav_width;
        transition: left 0.15s linear;

        .sidenav-header {
            display: none;
        }
    }

    .mobile-sidenav-show {
        left: 0;
        transition: left 0.15s linear;
    }
}


//---------------------------------------
//-------------CORES/TEMAS--------------- 
//---------------------------------------
.dark_degrade {
    .sidenav {
        background-color: rgb(32, 32, 32);
    }

    .sidenav-header {
        background-color: rgb(32, 32, 32);
    }
}

.dark {
    .sidenav {
        background-color: $bg_sidenav_dark;
    }

    .sidenav-header {
        background-color: $bg_topnav_dark;
    }
}


.sidenav .sidenav-body .sidenav-menu li {
    a.sidenav-expand-submenu::after{
        color: $primary;
    }

    a {
        i {
            color: $success;
        }

        span {
            color: $gray-800;
        }

        &:hover {
            background: transparent;
            background: linear-gradient(90deg, rgba(0,0,0,0.0) 0%, rgba(0,255,30,0.05) 50%, rgba(0,0,0,0) 100%);
            padding-left: 1rem;
            margin-left: -1rem;
            
            i {
                color: $primary;
            }

            span {
                color: $primary;
            }
        }
    }

    ul li a {
        color: $gray-800;

        &:hover {
            color: $primary;
        }
    }
}

.dark,
.dark_degrade {
    .sidenav .sidenav-body .sidenav-menu li {
        a {
            i {
                color: $primary;
            }
    
            span {
                color: $gray-300;
            }
    
            &:hover {
                background: transparent;
                background: linear-gradient(90deg, rgba(0,0,0,0.0) 0%, rgba(0,255,30,0.05) 50%, rgba(0,0,0,0) 100%);
                padding-left: 1rem;
                margin-left: -1rem;

                i {
                    color: $primary;
                }
    
                span {
                    color: $primary;
                }
            }
        }
    
        ul li a {
            color: $gray-300;
    
            &:hover {
                color: $primary;
            }
        }
    }
}