.right-config{
    position: fixed;
    width: 300px;
    z-index: 9;
    
    top: calc($topnav_height + 10px);
    right: -310px;
    transition: right 0.25s;
}

.right-config.show{
    right: 10px;
    transition: right 0.25s;
}