.iti{
    width: 100%;
}

.dark,
.dark_degrade{
    .iti__dropdown-content[_ngcontent-ng-c2444536406] {
        border-color: $gray-800;
    }
    
    .dropdown-menu[_ngcontent-ng-c2444536406] {
        background-color: $gray-900;
    }

    .search-container[_ngcontent-ng-c2444536406] input[_ngcontent-ng-c2444536406] {
        background-color: $gray-900;
        border-color: $gray-800;
    }
    .iti__country-list{
        .iti__country{
            display: flex;
            justify-content: space-around;
            text-align: center;
            color: rgb(200, 200, 200);
        }
    }
}