//
// Form Wizard
//

// twitter-bs-wizard


.steps {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    justify-content: space-evenly;
  
    .step[_ngcontent-ng-c124550483] {
      position: relative;
      width: auto;
    }

    .step-icon {
      display: inline-block;
      width: 56px;
      height: 56px;
      line-height: 54px;
      border: 1px solid rgba($primary, 0.2);
      color: $primary;
      text-align: center;
      border-radius: 50%;
      position: relative;
      z-index: 1;
      font-size: 20px;
  
      @media (max-width: 575.98px) {
        width: 40px;
        height: 40px;
        line-height: 38px;
      }
    }
  
    .step-title {
      margin-left: 6px;
    }
  
    .step {
      .step {
        &.done {
          .step-icon {
            background-color: $primary;
            color: $white;
          }
  
          .uil {
            &:before {
              content: "\e9c3";
            }
          }
        }
      }
    }
  
    .step {
      font-size: 14px;
      position: relative;
  
      @media (max-width: 575.98px) {
        padding: 0.5rem;
      }
  
      &.active {
        color: $gray-700;
        background-color: transparent;
  
        .step-icon {
          background-color: rgba($primary, 0.2);
          color: $primary;
          border-color: rgba($primary, 0.2);
        }
      }
  
    }
    .step.completed[_ngcontent-ng-c625643793]+.step[_ngcontent-ng-c625643793] {
      &::after {
        background-color: $primary;
      }
    }
  }