.fc-h-event .fc-event-main{
    text-wrap: wrap;
}

.fc td, .fc th{
    border: 1px solid $gray-300;
}

.fc {
    .fc-toolbar {

        .fc-toolbar-chunk{
            display: flex;
            align-items: center;
        }

        h2 {
            font-size: 16px;
            line-height: 30px;
            text-transform: uppercase;
        }

        @media (max-width: 770px) {

            .fc-left,
            .fc-right,
            .fc-center {
                float: none;
                display: block;
                text-align: center;
                clear: both;
                margin: 10px 0;
            }

            >*>* {
                float: none;
            }

            
        }

        .btn {
            text-transform: capitalize;
        }

    }

    .fc-list-day-cushion,
    .fc-col-header-cell{
        background-color: $light;
        a{
            text-decoration: none;
            color: $gray-600;
        }
    }

    .fc-col-header-cell-cushion {
        display: block;
        padding: 8px 4px;
    }

    .fc-daygrid-day-number{
        width: 24px;
        height: 24px;
        border-radius: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 12px;
        font-weight: $font-weight-medium;
        text-decoration: none;
        color: $gray-600;
        
        margin: 2px;
    }

    .fc-daygrid-day.fc-day-today{
        background-color: rgba($primary,0.1);
        .fc-daygrid-day-number{
            background-color: $primary;
            color: $white;
        }
    }

    //verificar quando houver 
    .fc-list-event:hover td{
        background: transparent;
    }

    .fc-list-event-dot{
        border-color: var(--#{$prefix}gray-300);
    }

    .fc-list-event-title{
        a{
            color: $white;
        }
    }

    .fc-col-header, .fc-daygrid-body, .fc-scrollgrid-sync-table{
        width: 100%;
    }
}

.table-bordered{
    border: 1px solid var(--#{$prefix}gray-300);
} 

.fc-theme-bootstrap a:not([href]){
    color: var(--#{$prefix}gray-700);
}

.fc {
    th.fc-widget-header {
        background: $gray-300;
        color: $gray-700;
        line-height: 20px;
        padding: 10px 0;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }
}

.fc-unthemed {

    .fc-content,
    .fc-divider,
    .fc-list-heading td,
    .fc-list-view,
    .fc-popover,
    .fc-row,
    tbody,
    td,
    th,
    thead {
        border-color: var(--#{$prefix}gray-300);
    }

    td.fc-today {
        background: lighten($gray-200, 2%);
    }
}

.fc-button {
    background: var(--#{$prefix}white);
    border-color: var(--#{$prefix}gray-300);
    color: $gray-700;
    text-transform: capitalize;
    box-shadow: none;
    padding: 6px 12px;
    height: auto;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: $primary;
    color: $white;
    text-shadow: none;
}

.fc-event {
    border-radius: 2px;
    border: none;
    cursor: move;
    font-size: 0.8125rem;
    margin: 5px 7px;
    padding: 5px 5px;
    text-align: center;
}

.fc-event,
.fc-event-dot {
    background-color: transparent;
}

.fc-event:hover {
    cursor: pointer;
}

.fc-daygrid-dot-event.fc-event-mirror, 
.fc-daygrid-dot-event:hover{
    background-color: transparent;
}

.fc-daygrid-dot-event{
    .fc-event-title{
        font-weight: $font-weight-semibold;
    }
}

#external-events {
    .external-event {
        text-align: left;
        padding: 8px 16px;
        margin: 6px 0;
    }
}


.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark {
    .fc-content{
      color: $light;  
    }
}

.fc-header-toolbar {
    direction: ltr;
}

.fc-toolbar>*>:not(:first-child) {
    margin-left: .75em;
}

.fc-toolbar{
    @media (max-width: 700px) {
        flex-direction: column;
        gap: 16px;
    }
}

.dark,
.dark_degrade{
    .fc .fc-list-day-cushion, .fc .fc-col-header-cell,
    .fc .fc-col-header-cell {
        background-color: $gray-900;
    }

    .fc-theme-standard .fc-scrollgrid,
    .fc td, 
    .fc th {
        border-color: $gray-600;
    }
}

.dark,
.dark_degrade{
    .fc{
        .fc-daygrid-day.fc-day-today{
            background-color: rgba($primary,0.1);
            .fc-daygrid-day-number{
                background-color: $primary;
                color: $white;
            }
        }
        
        .fc-event{
            color: $white;
        }

        .fc-event .fc-content {
            color: $white;
        }

        .fc-daygrid-event-dot{
            border-color: $white;
            color: $white;
        }

        .fc-daygrid-dot-event{
            color: $white;
        }
    }
}