/*_____CORES_____*/
$bg_site: rgb(245,245,245);
$bg_site_dark: #202020;

/*_____sidenav_____*/
//$bg_sidenav: #F4F4F4;
$bg_sidenav: rgb(250, 250, 250);
$icon_sidenav_color: #2b2b2b;

$bg_sidenav_dark: #2B2B2B;
$icon_sidenav_color_dark: #fff;

/*_____topnav_____*/
$bg_topnav: #469d39;
$bg_subheader: #e0e0e0;
$icon_topnav_color: #fff;

$bg_topnav_dark: #2D2D2D;
$bg_subheader_dark: #353535;
$icon_topnav_color_dark: #FFDE59;

//////////////////////
/*_____TAMANHOS_____*/
$sidenav_height: 100%;
$sidenav_width: 200px;

$topnav_height: 4rem;
$topnav_width: calc(100% - $sidenav_width);