html,
body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.content_site {
    height: calc(100% - 3rem);
    width: 100%;
}

.content_footer {
    background-color: rgba(255, 255, 255);
    position: absolute;
    right: 0;
    height: 3rem;
    width: 100%;
}

.notify-card {
    background-color: #fcfcfc;
}

// .content_pag
div.content_pag {
    background-color: $bg_site;
}

.dark {
    div.content_pag {
        background-color: $bg_site_dark;
    }
}

.dark_degrade {
    div.content_pag {
        background: rgb(32, 32, 32);
        background: linear-gradient(90deg, rgba(45, 45, 45, 1) 0%, rgba(35, 50, 60, 1) 50%, rgba(15, 45, 25, 1) 100%);
    }
}
// Fim .content_pag


// .CARD
div.card {
    border: none;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px;
    
    .card-header{
        background-color: rgb(255,255,255);
    }
}

.dark,
.dark_degrade {
    
    div.card {
        .card-header{
            background-color: $bg_topnav_dark;
        }
        background-color: $bg_topnav_dark;
        color: rgb(177, 177, 177);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
    }
}
// FIM .CARD

/*-------------LAYOUT--------------*/
/*---------------------------------*/
._layout_horizontal {
    .content_pag {
        transition: padding .25s;
    }

    .content_footer {
        width: 100%;
    }
}

._layout_horizontal._layout_horizontal.sub-header-fixed {
    .content_pag {
        padding-top: calc($topnav_height + calc($topnav_height - 0.7rem));
        transition: padding .25s;
    }
}

._layout_vertical.mini-sidenav {
    .content_pag {
        padding-left: 80px;
        transition: all linear 0.1s;
    }

    .content_footer {
        padding-left: 80px;
        transition: padding .25s;
    }
}

._layout_vertical {
    .content_pag {
        padding-left: $sidenav_width;
        transition: padding .25s;
    }

    .content_footer {
        padding-left: $sidenav_width;
        transition: padding .25s;
    }
}

.content_pag {
    min-height: 100%;
    width: 100%;
    padding-top: $topnav_height;
}

.mobile-sidenav {
    .content_pag {
        padding-left: 0;
    }
}

//////////////////////////////////////////////
//////////////////DARK MODE*//////////////////
//////////////////////////////////////////////
.dark_degrade {
    .content_footer {
        background: rgb(32, 32, 32);
        background: linear-gradient(90deg, rgba(40, 40, 40, 1) 0%, rgba(30, 45, 55, 1) 50%, rgba(10, 40, 20, 1) 100%);
        border-color: $gray-800 !important;

        span,
        a {
            color: rgb(145, 145, 145) !important;
        }
    }

    .modal-content {
        background: rgb(32, 32, 32);
        background: linear-gradient(90deg, rgba(45, 45, 45, 1) 0%, rgba(35, 50, 60, 1) 50%, rgba(15, 45, 25, 1) 100%);
    }
}

.dark {
    .content_footer {
        background-color: #1b1b1b;
        border-color: $gray-800 !important;

        span,
        a {
            color: rgb(139, 139, 139) !important;
        }
    }

    .modal-content {
        background-color: $bg-site-dark;

        .modal-header,
        .modal-footer {
            border-color: $gray-800 !important;
        }
    }
}

.dark,
.dark_degrade {

    .notify-card {
        background-color: rgba(30, 30, 30, 1);
    }

    .nav-tabs {
        border-color: $gray-800 !important;

        .nav-link.active {
            background-color: $gray-900 !important;
            border-color: $gray-800 !important;
        }

    }

    .table {
        border-color: $gray-700;

        :not(caption)>*>* {
            background-color: transparent !important;
        }
    }

    .border,
    .border-top,
    .border-left,
    .border-right,
    .border-bottom {
        border-color: $gray-700 !important;
    }

    .bg-light {
        background: rgb(40, 40, 40) !important;
    }
}

/*-------------------SCROLL BAR--------------------*/
//NORMAL
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: rgb(207, 207, 207);
}

::-webkit-scrollbar {
    width: .5rem;
    background-color: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    border-radius: 10px;
    background-color: rgb(150, 150, 150);
}

//DARK
.dark::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgb(48, 48, 48);
}

.dark::-webkit-scrollbar {
    background-color: #424242;
}

.dark::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 10px 6px rgba(0, 0, 0, 0.3);
    background-color: rgb(80, 80, 80);
}

//DARK DEGRADE
.dark_degrade::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(15, 25, 15, 1)
}

.dark_degrade::-webkit-scrollbar {
    background-color: rgba(15, 25, 15, 1);
}

.dark_degrade::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 10px 6px rgba(0, 0, 0, 0.3);
    background-color: rgb(35, 80, 35);
}

.card-usuario{
    background-color: rgb(255,255,255);
    border: 1px solid rgb(240,240,240);
}

.dark,
.dark_degrade{
    .card-usuario{
        background-color: rgb(20,20,20);
        border: 1px solid rgb(100,100,100);
    }
}