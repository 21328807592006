h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
label {
    color: #161616;
}

.dark, 
.dark_degrade{
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    span,
    label {
        color: #ececec;
    }
}

// Não remover o "!important"
// O bootstrap usa "!important' para essa classe, logo, para sobrescreve-la é preciso utilizá-lo.
.text-muted{
    color: #464646 !important;
}

.dark, 
.dark_degrade{
    // Não remover o "!important"
    // O bootstrap usa "!important' para essa classe, logo, para sobrescreve-la é preciso utilizá-lo.
    .text-muted{
        color: #c4c4c4 !important;
    }
}

/// FORMULARIOS ///
.form-control-sm{
    padding: 0.1rem 0rem;
}
.form-control{
    padding: 0.3rem 0rem;
}
.form-control-lg{
    padding: 0.5rem 0rem;
}

.form-control.ng-select > .ng-select-container,
.form-control-lg.ng-select > .ng-select-container,
.form-control-sm.ng-select > .ng-select-container {
        border: none !important;
        height: auto !important;
        min-height: 0px !important;
        position: static !important;
}
/// FORMULARIOS ///

/// BACKGROUNDS ///
div.bg-primary, 
tr.bg-primary {
    background-color: #469d39 !important;
}

.dark,
.dark_degrade{
    div.bg-primary, 
    tr.bg-primary {
        background-color: #469d39 !important;
    }
}
/// BACKGROUNDS ///

/// BADGE'S ///
/// Todos precisam do "!important", pelo menos no background
.badge.badge-primary{
    color: white;
    background-color: #469d39 !important;
}

.badge.badge-secondary{
    color: white;
    background-color: rgba(0,0,0,.5) !important;
}

.badge.badge-success{
    color: white;
    background-color: #198754 !important;
}

.badge.badge-danger{
    color: white;
    background-color: #DC3545 !important;
}

.badge.badge-warning{
    color: gray;
    background-color: rgb(255, 210, 25) !important;
}

.badge.badge-info{
    color: white;
    background-color: rgb(0, 150, 255) !important;
}

.badge.badge-light{
    color: gray;
    background-color: rgba(0,0,0,.05) !important;
}

.badge.badge-dark{
    color: gray;
    background-color: rgba(0,0,0,.9) !important;
}

.dark,
.dark_degrade{
    .badge-success{
        color: white;
        background-color: #198754 !important;
    }
    .badge-warning{
        color: rgb(75, 75, 75);
        background-color: rgb(255, 210, 25) !important;
    }
}
/// BADGE'S ///


/// ALERT'S ///
.alert{
    padding: 0.4rem !important;
}

.alert-secondary{
    background-color: rgba(40, 40, 40,.1) !important;
    color: rgba(10, 10, 10, .8) !important;
}

.alert-success{
    background-color: rgba(0, 210, 21, 0.5) !important;
    color: rgba(0, 80, 0, 1) !important;
}

.alert-danger{
    background-color: rgba(250, 0, 0, 0.5) !important;
    color: rgba(100, 0, 0, 1) !important;
}

.alert-warning{
    background-color: rgba(250, 200, 0, 0.5) !important;
    color: rgba(80, 80, 0, 1) !important;
}

.dark,
.dark_degrade{
    .alert-secondary{
        background-color: rgba(255,255,255,.05) !important;
        color:$gray-500 !important;
        border-color: $gray-600 !important;
    }

    .alert-success{
        background-color: rgba(100, 255, 100, 0.5) !important;
        border-color: rgba(0, 60, 0, 0.8);
        color: rgba(0, 60, 0, 1) !important;
    }
    
    .alert-danger{
        background-color: rgba(250, 70, 70, 0.5) !important;
        border-color: rgba(100, 0, 0, 1) !important;
        color: rgba(100, 0, 0, 1) !important;
    }
    
    .alert-warning{
        background-color: rgba(255, 240, 80, 0.55) !important;
        border-color: rgba(50, 50, 0, 1) !important;
        color: rgba(50, 50, 0, 1) !important;
    }
}
/// ALERT'S ///

/// BUTTONS ///
//------------- THEMA NORMAL
.btn-light{
    background-color: rgb(255,255,255) !important;
    color: $gray-400;
    border-color: rgb(240,240,240) !important;
}
.btn-light:hover{
    background-color: rgb(240,240,240) !important;
    color: $gray-400;
    border-color: rgb(220,220,220) !important;
}
.btn-outline-light{
    background-color: transparent !important;
    color: $gray-600 !important;
    border-color: rgb(200,200,200) !important;
}
.btn-outline-light:hover{
    background-color: rgba(0,0,0,.2) !important;
    color: $gray-400;
    border-color: rgba(0,0,0,.1) !important;
}

//--------------DARK
.dark,
.dark_degrade{
    
    //LIGHT
    .btn-light{
        background-color: rgb(60,60,60) !important;
        color: $gray-400;
        border-color: $bg-site-dark !important;
    }
    .btn-light:hover{
        background-color: rgba(100,100,100,1) !important;
        color: $gray-400;
        border-color: $bg-site-dark !important;
    }

    .btn-outline-light{
        background-color: transparent !important;
        color: $gray-600 !important;
        border-color: rgba(0,0,0,.1) !important;
    }
    .btn-outline-light:hover{
        background-color: rgba(0,0,0,.2) !important;
        color: $gray-400;
        border-color: rgba(0,0,0,.1) !important;
    }

    //DARK
    .btn-dark{
        background-color: rgba(255,255,255,.7) !important;
        color: $gray-700 !important;
        border-color: rgba(255,255,255,.7) !important;
    }
    .btn-dark:hover{
        background-color: rgba(255,255,255,.9) !important;
        color: $gray-700 !important;
        border-color: rgba(255,255,255,.9) !important;
    }

    .btn-outline-dark{
        background-color: transparent !important;
        color: $gray-500 !important;
        border-color: $gray-500 !important;
    }
    .btn-outline-dark:hover{
        background-color: rgba(255,255,255,.7) !important;
        color: $gray-700 !important;
        border-color: $gray-500 !important;
    }
}

/// BUTTONS /// 

/// TEXT-COLORS ///
.dark,
.dark_degrade{

    .text-primary{
        color: #469d39 !important;
    }
    .text-secondary{
        color: rgba(100, 100, 100, 1) !important;
    }
    .text-success{
        color: #198754 !important;
    }
    .text-warning{
        color: rgba(255, 200, 0, 1) !important;
    }
    .text-danger{
        color: #DC3545 !important;
    }
    .text-info{
        color: rgba(0, 180, 255, 0.8) !important;
    }
    .text-light{
        color: rgba(0, 0, 0, .8) !important;
    }
    .text-dark{
        color: rgba(255, 255, 255, 0.8) !important;
    }
}
/// FIM TEXT-COLORS ///

/// TEXT-BG-DARK
ngb-progressbar > div.text-bg-success{
    background-color: #469d39 !important;
}

.dark,
.dark_degrade{
    ngb-progressbar > div.text-bg-success{
        background-color: #469d39 !important;
    }   
}
/// FIM TEXT-BG-DARK

/// TABLE ///
.dark,
.dark_degrade{
    table{
        tbody{
            tr{
                td{
                    ///BADGE
                    .badge-primary{
                        color: white !important;
                        background-color: #469d39 !important;
                    }
                    .badge-secondary{
                        color: white !important;
                        background-color: rgba(0,0,0,.5) !important;
                    }
                    .badge-success{
                        color: white !important;
                        background-color: #198754 !important;
                    }
                    .badge-warning{
                        color: rgb(27, 27, 27) !important;
                        background-color: rgb(255, 210, 25) !important;
                    }
                    .badge-danger{
                        color: white !important;
                        background-color: #DC3545 !important;
                    }
                    .badge-info{
                        color: white !important;
                        background-color: rgb(0, 150, 255) !important;
                    }
                    .badge-light{
                        color: gray !important;
                        background-color: rgba(0,0,0,.05) !important;
                    }
                    .badge-dark{
                        color: gray !important;
                        background-color: rgba(0,0,0,.9) !important;
                    }
                    ///BADGE
                    /// 
                    /// 
                    /// TEXT
                    .text-primary{
                        color: rgba(0, 140, 255, 1) !important;
                    }
                    .text-secondary{
                        color: rgba(100, 100, 100, 1) !important;
                    }
                    .text-success{
                        color: #198754 !important;
                    }
                    .text-warning{
                        color: rgba(255, 200, 0, 1) !important;
                    }
                    .text-danger{
                        color: #DC3545 !important;
                    }
                    .text-info{
                        color: rgba(0, 180, 255, 0.8) !important;
                    }
                    .text-light{
                        color: rgba(0, 0, 0, .8) !important;
                    }
                    .text-dark{
                        color: rgba(255, 255, 255, 0.8) !important;
                    }
                    /// TEXT
                }
            }
        }
    }
}
/// TABLE ///

/// MODAL SIZE ///
.modal-lg .modal-dialog {
    max-width: 80% !important; 
    margin: auto;
    display: flex;
    align-items: center;
    height: 100%;
}

.modal-md .modal-dialog {
    max-width: 60% !important; 
    margin: auto;
    display: flex;
    align-items: center;
    height: 100%;
}

.modal-sm .modal-dialog {
    max-width: 40% !important; 
    margin: auto;
    display: flex;
    align-items: center;
    height: 100%;
}
/// MODAL SIZE ///