@import "variablesThema"; //Tamanhos, bordas, e variaveis bases - Editar aqui 
@import "variablesSite";  //Cores - Editar aqui 

@import "geral/geral.scss";

@import "components/topnav.scss";
@import "components/sidenav.scss";
@import "components/page_body.scss";
@import "components/rightConfig.scss";
@import "components/components.scss";

@import "plugins/_@imports.scss";
@import "plugins/_ngSelect.scss";
@import "plugins/_stepper.scss";
@import "plugins/_dropDown.scss";
@import "plugins/_full-calendar.scss";
@import "plugins/_intl_input.scss";
@import "plugins/color-picker.scss";
@import "plugins/editorText.scss";

.animate__animated {
    --animate-duration: .5s;
}

.spinner-border-sm{
    --bs-spinner-width: 1.5rem !important;
    --bs-spinner-height: 1.5rem !important;
    --bs-spinner-border-width: 0.3rem !important;
}

.spinner-border {
    border-color: $primary !important;
    border-right-color: transparent !important;
}

#seta {
    position: absolute;
    padding: 1px;
    bottom: 2px;
    right: 1.8rem;
    box-shadow: $gray-700 1px -1px 0 0px, $gray-400 2px -2px 0 1px;
    border: solid transparent;
    border-width: 0 0 2px 2px;
    transform: rotate(135deg);
}

#foto_user>img:hover ~ #seta{
    animation-name: animSeta;
    animation-duration: 0.7s;
    animation-iteration-count: infinite;
}

@keyframes animSeta {
    0% {
        bottom: 2px;
    }
    50%{
        bottom: -2px;
    }
    100%{
        bottom: 2px;
    }
  }

.btn-salvar {
    min-width: 10rem !important;
}

.progress, .progress-stacked{
    --bs-progress-bg: rgba(0,0,0,.2) !important;
    background-color: rgba(0,0,0,.2) !important;
    border-radius: 10px !important;
    height: 0.5rem !important;
}

.dark,
.dark_degrade{
    #seta{
        box-shadow: rgb(74, 77, 0) 1px -1px 0 0px, rgb(223, 182, 0) 2px -2px 0 1px;
    }

    .form-control{
        background-color: $gray-800 !important;
        color: white !important;
        border-color: $gray-700 !important;

        &:disabled {
            opacity: 0.6;
        }
    }
    
    input{
        color: white !important;
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus{
        -webkit-text-fill-color: $gray-400 !important;
        -webkit-box-shadow: 0 0 0 30px $gray-800 inset !important;
    }

    ::-webkit-input-placeholder {
        color: $gray-600 !important;
     }
     
    :-moz-placeholder { /* Firefox 18- */
        color: $gray-600 !important;  
     }
     
    ::-moz-placeholder {  /* Firefox 19+ */
        color: $gray-600 !important; 
    }
     
    :-ms-input-placeholder {  
        color: $gray-600 !important;  
    }

    input[type="date"]{
        color-scheme: dark;
    }
}

image-cropper{
    --cropper-overlay-color: 'black';
    --cropper-outline-color: 'black';
    [_nghost-ng-c1707197675] > div[_ngcontent-ng-c1707197675]{
        --cropper-overlay-color: 'black';
        --cropper-outline-color: 'black';
    }
}

.fa-solid.fa-spinner{
    animation: is-rotating 1s infinite;
}

@keyframes is-rotating {
    to {
      transform: rotate(1turn);
    }
  }